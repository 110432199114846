/**
 * App Redux Action Types
 */
export const COLLAPSED_SIDEBAR = 'COLLAPSED_SIDEBAR';
export const DARK_MODE = 'DARK_MODE';
export const BOXED_LAYOUT = 'BOXED_LAYOUT';
export const RTL_LAYOUT = 'RTL_LAYOUT';
export const MINI_SIDEBAR = 'MINI_SIDEBAR';
export const SEARCH_FORM_ENABLE = 'SEARCH_FORM_ENABLE';
export const CHANGE_THEME_COLOR = 'CHANGE_THEME_COLOR';
export const TOGGLE_SIDEBAR_IMAGE = 'TOGGLE_SIDEBAR_IMAGE';
export const SET_SIDEBAR_IMAGE = 'SET_SIDEBAR_IMAGE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const START_USER_TOUR = 'START_USER_TOUR';
export const STOP_USER_TOUR = 'STOP_USER_TOUR';
export const TOGGLE_DARK_SIDENAV = 'TOGGLE_DARK_SIDENAV';

// Chat App Actions
export const CHAT_WITH_SELECTED_USER = 'CHAT_WITH_SELECTED_USER';
export const SEND_MESSAGE_TO_USER = 'SEND_MESSAGE_TO_USER';
export const UPDATE_USERS_SEARCH = 'UPDATE_USERS_SEARCH';
export const SEARCH_USERS = 'SEARCH_USERS';
export const GET_RECENT_CHAT_USERS = 'GET_RECENT_CHAT_USERS';

// Mail App
export const GET_EMAILS = 'GET_EMAILS';
export const GET_EMAIL_SUCCESS = 'GET_EMAIL_SUCCESS';
export const GET_EMAIL_FAILURE = 'GET_EMAIL_FAILURE';
export const SET_EMAIL_AS_STAR = 'SET_EMAIL_AS_STAR';
export const READ_EMAIL = 'READ_EMAIL';
export const HIDE_LOADING_INDICATOR = 'HIDE_LOADING_INDICATOR';
export const FETCH_EMAILS = 'FETCH_EMAILS';
export const ON_SELECT_EMAIL = 'ON_SELECT_EMAIL';
export const UPDATE_EMAIL_SEARCH = 'UPDATE_EMAIL_SEARCH';
export const SEARCH_EMAIL = 'SEARCH_EMAIL';
export const ON_DELETE_MAIL = 'ON_DELETE_MAIL';
export const ON_BACK_PRESS_NAVIGATE_TO_EMAIL_LISTING = 'ON_BACK_PRESS_NAVIGATE_TO_EMAIL_LISTING';
export const GET_SENT_EMAILS = 'GET_SENT_EMAILS';
export const GET_INBOX = 'GET_INBOX';
export const GET_DRAFTS_EMAILS = 'GET_DRAFTS_EMAILS';
export const GET_SPAM_EMAILS = 'GET_SPAM_EMAILS';
export const GET_TRASH_EMAILS = 'GET_TRASH_EMAILS';
export const ON_EMAIL_MOVE_TO_FOLDER = 'ON_EMAIL_MOVE_TO_FOLDER';
export const SELECT_ALL_EMAILS = 'SELECT_ALL_EMAILS';
export const UNSELECT_ALL_EMAILS = 'UNSELECT_ALL_EMAILS';
export const ON_SEND_EMAIL = 'ON_SEND_EMAIL';
export const EMAIL_SENT_SUCCESSFULLY = 'EMAIL_SENT_SUCCESSFULLY';
export const FILTER_EMAILS_WITH_LABELS = 'FILTER_EMAILS_WITH_LABELS';
export const ADD_LABELS_INTO_EMAILS = 'ADD_LABELS_INTO_EMAILS';

// sidebar
export const TOGGLE_MENU = 'TOGGLE_MENU';

// Agency Sidebar
export const AGENCY_TOGGLE_MENU = 'AGENCY_TOGGLE_MENU';
export const CHANGE_AGENCY_LAYOUT_BG = 'CHANGE_AGENCY_LAYOUT_BG';

// ToDo App
export const GET_TODOS = 'GET_TODOS';
export const FETCH_TODOS = 'FETCH_TODOS';
export const ADD_NEW_TASK = 'ADD_NEW_TASK';
export const ON_SELECT_TODO = 'ON_SELECT_TODO';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const ON_BACK_TO_TODOS = 'ON_BACK_TO_TODOS';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const MARK_AS_STAR_TODO = 'MARK_AS_STAR_TODO';
export const DELETE_TODO = 'DELETE_TODO';
export const ADD_LABELS_INTO_THE_TASK = 'ADD_LABELS_INTO_THE_TASK';
export const GET_ALL_TODO = 'GET_ALL_TODO';
export const GET_COMPLETED_TODOS = 'GET_COMPLETED_TODOS';
export const GET_DELETED_TODOS = 'GET_DELETED_TODOS';
export const GET_STARRED_TODOS = 'GET_STARRED_TODOS';
export const GET_FILTER_TODOS = 'GET_FILTER_TODOS';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const COMPLETE_TASK = 'COMPLETE_TASK';
export const UPDATE_TASK_TITLE = 'UPDATE_TASK_TITLE';
export const UPDATE_TASK_DESCRIPTION = 'UPDATE_TASK_DESCRIPTION';
export const CHANGE_TASK_ASSIGNER = 'CHANGE_TASK_ASSIGNER';
export const ON_CHECK_BOX_TOGGLE_TODO_ITEM = 'ON_CHECK_BOX_TOGGLE_TODO_ITEM';
export const SELECT_ALL_TODO = 'SELECT_ALL_TODO';
export const GET_UNSELECTED_ALL_TODO = 'GET_UNSELECTED_ALL_TODO';
export const SELECT_STARRED_TODO = 'SELECT_STARRED_TODO';
export const SELECT_UNSTARRED_TODO = 'SELECT_UNSTARRED_TODO';
export const ON_LABEL_SELECT = 'ON_LABEL_SELECT';
export const ON_LABEL_MENU_ITEM_SELECT = 'ON_LABEL_MENU_ITEM_SELECT';
export const UPDATE_SEARCH = 'UPDATE_SEARCH';
export const SEARCH_TODO = 'SEARCH_TODO';
export const GET_TODOS_SUCCESS = 'GET_TODOS_SUCCESS';
export const GET_TODOS_FAILURE = 'GET_TODOS_FAILURE';

// Auth Actions
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE';
export const LOGIN_FACEBOOK_USER = 'LOGIN_FACEBOOK_USER';
export const LOGIN_GOOGLE_USER = 'LOGIN_GOOGLE_USER';
export const LOGIN_TWITTER_USER = 'LOGIN_TWITTER_USER';
export const LOGIN_GITHUB_USER = 'LOGIN_GITHUB_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE';

// Feedbacks
export const GET_FEEDBACKS = 'GET_FEEDBACKS';
export const GET_FEEDBACKS_SUCCESS = 'GET_FEEDBACKS_SUCCESS';
export const GET_FEEDBACKS_FAILURE = 'GET_FEEDBACKS_FAILURE';
export const GET_ALL_FEEDBACKS = 'GET_ALL_FEEDBACKS';
export const ON_CHANGE_FEEDBACK_PAGE_TABS = 'ON_CHANGE_FEEDBACK_PAGE_TABS';
export const MAKE_FAVORITE_FEEDBACK = 'MAKE_FAVORITE_FEEDBACK';
export const ON_DELETE_FEEDBACK = 'ON_DELETE_FEEDBACK';
export const VIEW_FEEDBACK_DETAILS = 'VIEW_FEEDBACK_DETAILS';
export const ADD_NEW_FEEDBACK = 'ADD_NEW_FEEDBACK';
export const SHOW_FEEDBACK_LOADING_INDICATOR = 'SHOW_FEEDBACK_LOADING_INDICATOR';
export const HIDE_FEEDBACK_LOADING_INDICATOR = 'HIDE_FEEDBACK_LOADING_INDICATOR';
export const NAVIGATE_TO_BACK = 'NAVIGATE_TO_BACK';
export const REPLY_FEEDBACK = 'REPLY_FEEDBACK';
export const SEND_REPLY = 'SEND_REPLY';
export const UPDATE_SEARCH_IDEA = 'UPDATE_SEARCH_IDEA';
export const ON_SEARCH_IDEA = 'ON_SEARCH_IDEA';
export const ON_COMMENT_FEEDBACK = 'ON_COMMENT_FEEDBACK';

// ecommerce
export const ON_DELETE_ITEM_FROM_CART = 'ON_DELETE_ITEM_FROM_CART';
export const ON_QUANTITY_CHANGE = 'ON_QUANTITY_CHANGE';
export const ON_ADD_ITEM_TO_CART = 'ON_ADD_ITEM_TO_CART';
//crm 
export const ADD_NEW_CLIENT = 'ADD_NEW_CLIENT';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const GET_ASSET_DETAILS = 'GET_ASSET_DETAILS';
export const SET_TAB_VALUE = 'SET_TAB_VALUE';
export const STORE_ASSET_DATA = 'STORE_ASSET_DATA';
export const GET_PARTNER_DETAILS = 'GET_PARTNER_DETAILS';
export const STORE_PARTNER_DATA = 'STORE_PARTNER_DATA';
export const GET_ISSUE_TYPE_DETAILS = 'GET_ISSUE_TYPE_DETAILS';
export const SET_ISSUE_TYPE_DETAILS = 'SET_ISSUE_TYPE_DETAILS';
export const GET_STATUS_TYPE_DETAILS = 'GET_STATUS_TYPE_DETAILS';
export const SET_STATUS_TYPE_DETAILS = 'SET_STATUS_TYPE_DETAILS'
export const GET_IMAGE_ASSET_INFO = 'GET_IMAGE_ASSET_INFO';
export const STORE_IMAGE_ASSET_INFO = 'STORE_IMAGE_ASSET_INFO';
export const GET_CONNECTED_GRAPH_DATA = 'GET_CONNECTED_GRAPH_DATA';
export const SET_CONNECTED_GRAPH_DATA = 'SET_CONNECTED_GRAPH_DATA';
export const CLEAR_CONNECTED_GRAPH_DATA = 'CLEAR_CONNECTED_GRAPH_DATA';
export const GET_ASSET_OVERVIEW_GRAPH = 'GET_ASSET_OVERVIEW_GRAPH';
export const STORE_ASSET_OVERVIEW_GRAPH = 'STORE_ASSET_OVERVIEW_GRAPH';
export const GET_RPI_OVERVIEW_GRAPH = 'GET_RPI_OVERVIEW_GRAPH';
export const STORE_RPI_OVERVIEW_GRAPH = 'STORE_RPI_OVERVIEW_GRAPH';
export const GET_CONTENT_TYPES = 'GET_CONTENT_TYPES';
export const SET_CONTENT_TYPES = 'SET_CONTENT_TYPES';
export const START_LOADER_CRM = 'START_LOADER_CRM';
export const STOP_LOADER_CRM = 'STOP_LOADER_CRM';
export const GET_ISSUE_TYPE_GRAPH = 'GET_ISSUE_TYPE_GRAPH';
export const STORE_ISSUE_TYPE_GRAPH = 'STORE_ISSUE_TYPE_GRAPH';

export const GET_PARTNER_GRAPH = 'GET_PARTNER_GRAPH';
export const STORE_PARTNER_GRAPH = 'STORE_PARTNER_GRAPH';

//Log Details
export const GET_LOG_DETAILS_TABLE_DATA = 'GET_LOG_DETAILS_TABLE_DATA';
export const SET_LOG_DETAILS_TABLE_DATA = 'SET_LOG_DETAILS_TABLE_DATA';
export const GET_LOG_DETAILS_TABLE_DOWNLOADED_DATA = 'GET_LOG_DETAILS_TABLE_DOWNLOADED_DATA';
export const GET_ASSET_DETAILS_OF_LOG_PAGE = 'GET_ASSET_DETAILS_OF_LOG_PAGE';
export const GET_ASSET_QC_DETAILS = 'GET_ASSET_QC_DETAILS';
export const SET_ASSET_QC_DETAILS = 'SET_ASSET_QC_DETAILS';
export const SET_ASSET_DETAIL_OF_LOG = 'SET_ASSET_DETAIL_OF_LOG';
export const START_LOADER = 'START_LOADER';
export const STOP_LOADER = 'STOP_LOADER';
export const SEND_NOTIFICATION = 'SEND_NOTIFICATION';
export const STATUS_DATA = 'STATUS_DATA';
export const GET_RESOURCE_MANAGEMENT_DATA = 'GET_RESOURCE_MANAGEMENT_DATA';
export const SET_RESOURCE_MANAGEMENT_DATA = 'SET_RESOURCE_MANAGEMENT_DATA';
export const GET_SEARCH_RESOURCE_MANAGEMENT_DATA = 'GET_SEARCH_RESOURCE_MANAGEMENT_DATA';
export const GET_UPDATE_RESOURCE_MANAGEMENT_DATA = "GET_UPDATE_RESOURCE_MANAGEMENT_DATA";
export const SET_RESOURCE_MANAGEMENT_DATA_FOR_FILTER ="SET_RESOURCE_MANAGEMENT_DATA_FOR_FILTER";
export const GET_RESOURCE_MANAGEMENT_DATA_FOR_FILTER ="GET_RESOURCE_MANAGEMENT_DATA_FOR_FILTER";
export const GET_CONTENT_CATALOGUE_DATA = 'GET_CONTENT_CATALOGUE_DATA';
export const SET_CONTENT_CATALOGUE_DATA = 'SET_CONTENT_CATALOGUE_DATA';
export const GET_INGESTION_LOG_DATA = 'GET_INGESTION_LOG_DATA';
export const SET_NGESTION_LOG_DATA = 'SET_NGESTION_LOG_DATA';
//QOE
export const GET_WIDGET_DATA = 'GET_WIDGET_DATA';
export const SET_WIDGET_DATA = 'SET_WIDGET_DATA';
export const START_LOADING_QOE = 'START_LOADING_QOE';
export const STOP_LOADING_QOE = 'STOP_LOADING_QOE';
export const EXPERINCE_DATA = 'EXPERINCE_DATA';
export const SET_EXPERINCE_DATA_DEVICE = 'SET_EXPERINCE_DATA_DEVICE';
export const SET_EXPERINCE_DATA_CONTENT = 'SET_EXPERINCE_DATA_CONTENT';
export const GET_THRESHOLD = 'GET_THRESHOLD';
export const SET_THRESHOLD = 'SET_THRESHOLD'
export const GET_FILTERS = 'GET_FILTERS';
export const SET_FILTERS = 'SET_FILTERS';
export const GET_VIDEO_FAILURE_DATA = 'GET_VIDEO_FAILURE_DATA';
export const SET_VIDEO_FAILURE_DATA = 'SET_VIDEO_FAILURE_DATA' ;
export const GET_CONCURRENT_PLAYS = 'GET_CONCURRENT_PLAYS';
export const SET_CONCURRENT_PLAYS = 'SET_CONCURRENT_PLAYS';
export const GET_TOTAL_MINUTES = 'GET_TOTAL_MINUTES';
export const SET_TOTAL_MINUTES =  'SET_TOTAL_MINUTES';
export const GET_AVG_COMPLETION = 'GET_AVG_COMPLETION';
export const SET_AVG_COMPLETION = 'SET_AVG_COMPLETION';
export const GET_VIDEO_FAIL = 'GET_VIDEO_FAIL';
export const SET_VIDEO_FAIL = 'SET_VIDEO_FAIL';
export const GET_UNIQUE_VIEWERS = 'GET_UNIQUE_VIEWERS';
export const SET_UNIQUE_VIEWERS = 'SET_UNIQUE_VIEWERS';
export const GET_UNIQUE_DEVICES = 'GET_UNIQUE_DEVICES';
export const SET_UNIQUE_DEVICES = 'SET_UNIQUE_DEVICES';
export const GET_MINUTES_PER_UNIQUE_DEVICES = 'GET_MINUTES_PER_UNIQUE_DEVICES';
export const SET_MINUTES_PER_UNIQUE_DEVICES = 'SET_MINUTES_PER_UNIQUE_DEVICES';
export const GET_USER_ATTRITION = 'GET_USER_ATTRITION';
export const SET_USER_ATTRITION = 'SET_USER_ATTRITION'
export const GET_SUCCESSFUL_PLAYS = 'GET_SUCCESSFUL_PLAYS';
export const SET_SUCCESSFUL_PLAYS = 'SET_SUCCESSFUL_PLAYS';
export const GET_REBUFFERING_PERCENTAGE = 'GET_REBUFFERING_PERCENTAGE';
export const SET_REBUFFERING_PERCENTAGE = 'SET_REBUFFERING_PERCENTAGE';
export const GET_VIDEO_PLAYS_AND_FAILURE = 'GET_VIDEO_PLAYS_AND_FAILURE';
export const SET_VIDEO_PLAYS_AND_FAILURE = 'SET_VIDEO_PLAYS_AND_FAILURE';
export const GET_AVERAGE_BITRATE = 'GET_AVERAGE_BITRATE';
export const SET_AVERAGE_BITRATE = 'SET_AVERAGE_BITRATE';
export const GET_EXIT_BEFORE_VIDEO_START = 'GET_EXIT_BEFORE_VIDEO_START';
export const SET_EXIT_BEFORE_VIDEO_START = 'SET_EXIT_BEFORE_VIDEO_START';
export const GET_CONNECTION_INDUCED_REBIFFERING_RATIO = 'GET_CONNECTION_INDUCED_REBIFFERING_RATIO';
export const SET_CONNECTION_INDUCED_REBIFFERING_RATIO = 'SET_CONNECTION_INDUCED_REBIFFERING_RATIO';
export const GET_VIDEO_START_TIME = 'GET_VIDEO_START_TIME';
export const SET_VIDEO_START_TIME = 'SET_VIDEO_START_TIME';
export const GET_VIDEO_RESTART_TIME = 'GET_VIDEO_RESTART_TIME';
export const SET_VIDEO_RESTART_TIME = 'SET_VIDEO_RESTART_TIME';
export const GET_RENDERING_QUALITY = 'GET_RENDERING_QUALITY';
export const SET_RENDERING_QUALITY = 'SET_RENDERING_QUALITY';
export const GET_ENDED_PLAY = 'GET_ENDED_PLAY';
export const SET_ENDED_PLAY = 'SET_ENDED_PLAY';
export const GET_REBUFFERING_RATIO = 'GET_REBUFFERING_RATIO';
export const GET_BANDWIDTH = 'GET_BANDWIDTH';
export const SET_BANDWIDTH = 'SET_BANDWIDTH';
export const GET_AVERAGE_FRAME_RATE = 'GET_AVERAGE_FRAME_RATE';
export const SET_AVERAGE_FRAME_RATE = 'SET_AVERAGE_FRAME_RATE';
export const GET_ENDED_PLAYS_PER_UNIQUE_DEVICE = 'GET_ENDED_PLAYS_PER_UNIQUE_DEVICE';
export const SET_ENDED_PLAYS_PER_UNIQUE_DEVICE = 'SET_ENDED_PLAYS_PER_UNIQUE_DEVICE';
export const GET_ATTEMPTS = 'GET_ATTEMPTS';
export const SET_ATTEMPTS = 'SET_ATTEMPTS';
export const SET_REBUFFERING_RATIO = 'SET_REBUFFERING_RATIO';
export const GET_REAL_TIME_PAGE_DATA = 'GET_REAL_TIME_PAGE_DATA';
export const SET_REAL_TIME_PAGE_DATA = 'SET_REAL_TIME_PAGE_DATA';
export const GET_MITIGATION_ANALYSIS_PAGE_DATA = 'GET_MITIGATION_ANALYSIS_PAGE_DATA';
export const SET_MITIGATION_ANALYSIS_PAGE_DATA = 'SET_MITIGATION_ANALYSIS_PAGE_DATA';
export const GET_ANOMALIES_DETECTED_PAGE_DATA = 'GET_ANOMALIES_DETECTED_PAGE_DATA';
export const SET_ANOMALIES_DETECTED_PAGE_DATA = 'SET_ANOMALIES_DETECTED_PAGE_DATA';
export const GET_ANOMALIES_RCA_PAGE_DATA = 'GET_ANOMALIES_RCA_PAGE_DATA';
export const SET_ANOMALIES_RCA_PAGE_DATA = 'SET_ANOMALIES_RCA_PAGE_DATA';
export const GET_ANOMALIES_MITIGATION_PAGE_DATA = 'GET_ANOMALIES_MITIGATION_PAGE_DATA';
export const SET_ANOMALIES_MITIGATION_PAGE_DATA = 'SET_ANOMALIES_MITIGATION_PAGE_DATA';
export const GET_ANOMALIES_CLUSTER_PAGE_DATA = 'GET_ANOMALIES_CLUSTER_PAGE_DATA';
export const SET_ANOMALIES_CLUSTER_PAGE_DATA = 'SET_ANOMALIES_CLUSTER_PAGE_DATA';
export const GET_ANOMALIES_CLUSTER_SESSION_PAGE_DATA = 'GET_ANOMALIES_CLUSTER_SESSION_PAGE_DATA';
export const SET_ANOMALIES_CLUSTER_SESSION_PAGE_DATA = 'SET_ANOMALIES_CLUSTER_SESSION_PAGE_DATA';
export const SET_REAL_TIME_PAGE_DATA_COMBINE = 'SET_REAL_TIME_PAGE_DATA_COMBINE';
export const CLEAR_REAL_TIME_DATA_COMBINE = 'CLEAR_REAL_TIME_DATA_COMBINE';
export const GET_USER_ENGAGEMENT_PAGE_DATA = 'GET_USER_ENGAGEMENT_PAGE_DATA';
export const SET_USER_ENGAGEMENT_PAGE_DATA = 'SET_USER_ENGAGEMENT_PAGE_DATA';
export const GET_QUALITY_EXPERIENCE_PAGE_DATA = 'GET_QUALITY_EXPERIENCE_PAGE_DATA';
export const SET_QUALITY_EXPERIENCE_PAGE_DATA = 'SET_QUALITY_EXPERIENCE_PAGE_DATA';
export const SET_METRIC_TYPE = 'SET_METRIC_TYPE';
export const CLEAR_ALL_METRICS = 'CLEAR_ALL_METRICS';
export const CLEAR_IMAGE_ASSET_INFO = 'CLEAR_IMAGE_ASSET_INFO';
export const SET_FAVORITE_METRIC = 'SET_FAVORITE_METRIC';
export const GET_FAVORITE_METRIC = 'GET_FAVORITE_METRIC';
export const SET_FAV = 'SET_FAV';
export const GET_VALIDATE = 'GET_VALIDATE';
export const SET_APPLY_MITIGATION = 'SET_APPLY_MITIGATION';
export const GET_ALL_MITIGATION = 'GET_ALL_MITIGATION';
export const SET_ALL_MITIGATION = 'SET_ALL_MITIGATION';
export const GET_SECOND_MITIGATION = 'GET_SECOND_MITIGATION';
export const SET_SECOND_MITIGATION = 'SET_SECOND_MITIGATION';
export const GET_THIRD_MITIGATION = 'GET_THIRD_MITIGATION';
export const SET_THIRD_MITIGATION = 'SET_THIRD_MITIGATION';
export const CLEAR_ALL_MITIGATION_DATA_TABLE = 'CLEAR_ALL_MITIGATION_DATA_TABLE';
export const GET_AUTO_MITIGATION_STATUS = 'GET_AUTO_MITIGATION_STATUS';
export const SET_AUTO_MITIGATION_STATUS = 'SET_AUTO_MITIGATION_STATUS';
export const GET_CITIES = 'GET_CITIES';
export const SET_CITIES = 'SET_CITIES';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const TOGGLE_MITIGATION = 'TOGGLE_MITIGATION';
export const APPLY_MANUAL_MITIGATION = 'APPLY_MANUAL_MITIGATION';
export const SET_TAB_VALUE_EXPERT = 'SET_TAB_VALUE_EXPERT';
export const SET_TAB_VALUE_MITIGATION = 'SET_TAB_VALUE_MITIGATION';
export const GET_NUMBER_OF_MITIGATION_APPLIED = 'GET_NUMBER_OF_MITIGATION_APPLIED';
export const SET_NUMBER_OF_MITIGATION_APPLIED = 'SET_NUMBER_OF_MITIGATION_APPLIED';
export const GET_IMPROVEMENT_IN_UEI = 'GET_IMPROVEMENT_IN_UEI';
export const SET_IMPROVEMENT_IN_UEI = 'SET_IMPROVEMENT_IN_UEI';
export const GET_DEGRADATION_IN_UEI = 'GET_DEGRADATION_IN_UEI';
export const SET_DEGRADATION_IN_UEI = 'SET_DEGRADATION_IN_UEI';
export const GET_AVERAGE_STARTUP_BUFFER_LENGTH = 'GET_AVERAGE_STARTUP_BUFFER_LENGTH';
export const SET_AVERAGE_STARTUP_BUFFER_LENGTH = 'SET_AVERAGE_STARTUP_BUFFER_LENGTH';
export const GET_AVERAGE_REBUFFERING_BUFFER_LENGTH = 'GET_AVERAGE_REBUFFERING_BUFFER_LENGTH';
export const SET_AVERAGE_REBUFFERING_BUFFER_LENGTH = 'SET_AVERAGE_REBUFFERING_BUFFER_LENGTH';
export const GET_TASK_STATUS= 'GET_TASK_STATUS';
export const SET_TASK_STATUS = 'SET_TASK_STATUS';
export const SET_GLOBAL_SETTING="SET_GLOBAL_SETTING"
export const GET_GLOBAL_SETTING="GET_GLOBAL_SETTING"
export const SET_GLOBAL_SETTING_POST="SET_GLOBAL_SETTING_POST"
export const GET_GLOBAL_SETTING_POST="GET_GLOBAL_SETTING_POST"
export const SET_ANOMALIES_DETECT_POST="SET_ANOMALIES_DETECT_POST"
export const GET_ANOMALIES_DETECT_POST="GET_ANOMALIES_DETECT_POST"
export const SET_SECOND_ANOMALIES_DETECT_POST="SET_SECOND_ANOMALIES_DETECT_POST"
export const GET_SECOND_ANOMALIES_DETECT_POST="GET_SECOND_ANOMALIES_DETECT_POST"
export const SET_CSV_ANOMALIES_DETECT_POST="SET_CSV_ANOMALIES_DETECT_POST"
export const GET_CSV_ANOMALIES_DETECT_POST="GET_CSV_ANOMALIES_DETECT_POST"
export const SET_RCA_POST="SET_RCA_POST"
export const GET_RCA_POST="GET_RCA_POST"
export const SET_METRIC_TYPE_FULLNAME="SET_METRIC_TYPE_FULLNAME"
export const SET_ANALYSIS_COUNTS_POST="SET_ANALYSIS_COUNTS_POST"
export const GET_ANALYSIS_COUNTS_POST="GET_ANALYSIS_COUNTS_POST"
export const SET_THIRD_ANOMALIES_DETECT_POST="SET_THIRD_ANOMALIES_DETECT_POST"
export const GET_THIRD_ANOMALIES_DETECT_POST="GET_THIRD_ANOMALIES_DETECT_POST"
export const SET_ESTIMATED_ROOT_SECOND_POST="SET_ESTIMATED_ROOT_SECOND_POST"
export const SET_ESTIMATED_ROOT_SECOND_POST_REQUEST="SET_ESTIMATED_ROOT_SECOND_POST_REQUEST"
export const GET_ESTIMATED_ROOT_SECOND_POST_REQUEST = "GET_ESTIMATED_ROOT_SECOND_POST_REQUEST"
export const GET_ESTIMATED_ROOT_SECOND_POST="GET_ESTIMATED_ROOT_SECOND_POST"
export const SET_ESTIMATED_ROOT_RCA_BUCKET="SET_ESTIMATED_ROOT_RCA_BUCKET"
export const GET_ESTIMATED_ROOT_RCA_BUCKET="GET_ESTIMATED_ROOT_RCA_BUCKET"
export const SET_CONFIG_MITI_BUCKET="SET_CONFIG_MITI_BUCKET"
export const GET_CONFIG_MITI_BUCKET="GET_CONFIG_MITI_BUCKET"
export const DELETE_CONFIG_MITI_BUCKET="DELETE_CONFIG_MITI_BUCKET"
export const SET_ANOMALIES_PLAYBACK_FAILURE_POST="SET_ANOMALIES_PLAYBACK_FAILURE_POST"
export const GET_ANOMALIES_PLAYBACK_FAILURE_POST="GET_ANOMALIES_PLAYBACK_FAILURE_POST"
export const SET_ANOMALIES_PLAYBACK_FAILURE_BY_VID_POST="SET_ANOMALIES_PLAYBACK_FAILURE_BY_VID_POST"
export const GET_ANOMALIES_PLAYBACK_FAILURE_BY_VID_POST="GET_ANOMALIES_PLAYBACK_FAILURE_BY_VID_POST"

export const SET_ERROR_RECORDS_DATA="SET_ERROR_RECORDS_DATA"
export const GET_ERROR_RECORDS_DATA="GET_ERROR_RECORDS_DATA"

export const GET_METIGATION_TYPE_BUCKET="GET_METIGATION_TYPE_BUCKET"
export const SET_METIGATION_TYPE_BUCKET="SET_METIGATION_TYPE_BUCKET"
export const GET_ADD_MITIGATION_BUCKET="GET_ADD_MITIGATION_BUCKET"
export const GET_UPDATE_MITIGATION_BUCKET="GET_UPDATE_MITIGATION_BUCKET"

export const SET_ADD_MITIGATION_BUCKET="SET_ADD_MITIGATION_BUCKET"
export const GET_APPLY_MITIGATION_FOR_RCA="GET_APPLY_MITIGATION_FOR_RCA"
export const SET_APPLY_MITIGATION_FOR_RCA="SET_APPLY_MITIGATION_FOR_RCA"
export const GET_ADD_TO_RCA_BUCKETS="GET_ADD_TO_RCA_BUCKETS"
export const GET_UPDATE_TO_RCA_BUCKETS="GET_UPDATE_TO_RCA_BUCKETS"
export const SET_ADD_TO_RCA_BUCKETS="SET_ADD_TO_RCA_BUCKETS"
export const GET_LABLED_DATA_RECORDS_POST="GET_LABLED_DATA_RECORDS_POST"
export const SET_ANOMALIES_DETECT_POST_RECORD_CSV="SET_ANOMALIES_DETECT_POST_RECORD_CSV"
export const GET_ANOMALIES_DETECT_POST_RECORD_CSV="GET_ANOMALIES_DETECT_POST_RECORD_CSV"
export const SET_ERC_POST_RECORD_CSV="SET_ERC_POST_RECORD_CSV"
export const GET_ERC_POST_RECORD_CSV="GET_ERC_POST_RECORD_CSV"
export const GET_NOTIFICATION = 'GET_NOTIFICATION'
export const SET_NOTIFICATION = 'SET_NOTIFICATION'
export const SET_QC_DEEPLINK_TOKEN ='SET_QC_DEEPLINK_TOKEN'
export const GET_QC_DEEPLINK_TOKEN ='GET_QC_DEEPLINK_TOKEN'
export const SET_DOWNLOAD_LABELED_RECORD_CSV="SET_DOWNLOAD_LABELED_RECORD_CSV"
export const GET_DOWNLOAD_LABELED_RECORD_CSV="GET_DOWNLOAD_LABELED_RECORD_CSV"

// location
export const GET_LOCATION_COORDINATES="GET_LOCATION_COORDINATES"
export const SET_LOCATION_COORDINATES="SET_LOCATION_COORDINATES"
export const GET_ERROR_LOCATION_COORDINATES="GET_ERROR_LOCATION_COORDINATES"
export const SET_ERROR_LOCATION_COORDINATES="SET_ERROR_LOCATION_COORDINATES"
export const GET_PLAY_COUNT="GET_PLAY_COUNT"
export const SET_PLAY_COUNT="SET_PLAY_COUNT"
export const GET_ERROR_COUNT="GET_ERROR_COUNT"
export const SET_ERROR_COUNT="SET_ERROR_COUNT"
export const GET_CONCURRENT_COUNT="GET_CONCURRENT_COUNT"
export const SET_CONCURRENT_COUNT="SET_CONCURRENT_COUNT"

//Pending In Queue
export const GET_PENDING_IN_QUEUE_TABLE_DATA = 'GET_PENDING_IN_QUEUE_TABLE_DATA';
export const SET_PENDING_IN_QUEUE_TABLE_DATA = 'SET_PENDING_IN_QUEUE_TABLE_DATA';
export const GET_PENDING_IN_QUEUE_TABLE_DOWNLOADED_DATA = 'GET_PENDING_IN_QUEUE_TABLE_DOWNLOADED_DATA';
export const GET_PENDING_IN_QUEUE_GRAPH_DATA = 'GET_PENDING_IN_QUEUE_GRAPH_DATA';
export const SET_PENDING_IN_QUEUE_GRAPH_DATA = 'SET_PENDING_IN_QUEUE_GRAPH_DATA';
export const CLEAR_PENDING_IN_QUEUE_GRAPH_DATA = 'CLEAR_PENDING_IN_QUEUE_GRAPH_DATA';
export const GET_ASSET_DETAILS_OF_PENDING_IN_QUEUE_PAGE = 'GET_ASSET_DETAILS_OF_PENDING_IN_QUEUE_PAGE';
export const SET_ASSET_DETAILS_OF_PENDING_IN_QUEUE_PAGE = 'SET_ASSET_DETAILS_OF_PENDING_IN_QUEUE_PAGE';

//AI
export const GET_MITIGATION_AI = 'GET_MITIGATION_AI';
export const SET_MITIGATION_AI = 'SET_MITIGATION_AI';

// User Management
export const GET_USER_MANAGEMENT_LIST = 'GET_USER_MANAGEMENT_LIST';
export const SET_USER_MANAGEMENT_LIST = 'SET_USER_MANAGEMENT_LIST';
export const CREATE_USER_LIST = 'CREATE_USER_LIST';
export const SET_USER_LIST = 'SET_USER_LIST';
export const EDIT_USER_LIST = 'EDIT_USER_LIST';
export const SET_EDIT_USER_LIST = 'SET_EDIT_USER_LIST';
export const GET_USER_ROLES = 'GET_USER_ROLE';
export const SET_USER_ROLES = 'SET_USER_ROLE';
export const CREATE_USER_ROLES = 'CREATE_USER_ROLES';
export const GET_USER_ROLES_LIST = 'GET_USER_ROLES_LIST';
export const SET_USER_ROLES_LIST = 'SET_USER_ROLES_LIST';
export const GET_USER_ACCOUNT = 'GET_USER_ACCOUNT';
export const SET_USER_ACCOUNT = 'SET_USER_ACCOUNT';
export const USER_CHANGE_PASSWORD = 'USER_CHANGE_PASSWORD';
export const CREATE_NEW_PASSWORD = 'CREATE_NEW_PASSWORD';
export const USER_RESET_EMAIL = 'USER_RESET_EMAIL';
export const USER_LIST_ENABLED = 'USER_LIST_ENABLED';
export const USER_LIST_STATUS = 'USER_LIST_STATUS';
export const SET_USER_LIST_STATUS = 'SET_USER_LIST_STATUS';
export const GET_PERMISSIONS_LIST = 'GET_PERMISSIONS_LIST';
export const SET_PERMISSIONS_LIST = 'SET_PERMISSIONS_LIST';
export const GET_ROLE_PERMISSION = 'GET_ROLE_PERMISSION';
export const SET_ROLE_PERMISSION = 'SET_ROLE_PERMISSION';
export const GET_USER_ROLE_PERMISSION = 'GET_USER_ROLE_PERMISSION';
export const SET_USER_ROLE_PERMISSION = 'SET_USER_ROLE_PERMISSION';
export const GET_PROJECT = 'GET_PROJECT';
export const SET_PROJECT = 'SET_PROJECT';