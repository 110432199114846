const GlobalProps = {
    dateTimeFormat: "DD MMM YYYY HH:mm:ss",
    dateFormat: "DD MMM YYYY",
    DEFAULT_PAGE_OPTION: {
      recordsPerPage: 10,
      pageSize: 10,
      pageNo: 1,
      filter: {},
    },
  };
  
  export default GlobalProps;