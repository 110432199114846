import React from 'react'  
import "./_video-qc.scss";

import BasicTab from 'Components/BasicTab' 
export default function Overview(props){
   return (
      <div className='ecom-dashboard-wrapper'> 
          <BasicTab/>  
       </div>
   )
}
