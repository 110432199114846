import appLocaleData from 'react-intl/locale-data/zh';
import zhMessages from '../locales/zh-Hans';

const ZhLan = {
   messages: {
      ...zhMessages
   },
   locale: 'zh-Hans-CN',
   data: appLocaleData
};
export default ZhLan;